import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import getFirebase from "../components/Firebase/firebase.js"

import firebase from 'firebase/app';
import 'firebase/firestore'

// import CalendarPicker from '../components/CalendarPicker/CalendarPicker'
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css';
import moment from 'moment'

import { Button, Form, FormGroup, FormFeedback, Input,
  Row, Col,
  Container
} from 'reactstrap';

const DAY_LABELS = ['一', '二', '三', '四', '五', '六', '日']
const MONTH_LABELS = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

export default ({ data }) => {
  const car = data.carsJson
  return (
    <CarDetails car={car}/>
  )
}
export const query = graphql`
  query($model: String!) {
    carsJson( model: { eq: $model } ) {
      model
      image
      made
      price
      type
      stripeTestSKU
      stripeProdSKU
    }
  }
`


const CarDetails = class CarDetails extends React.Component {
  constructor(props) {
    super(props)
    // const date = new Date()
    // const startDate = date.getTime()
    // this.DEFAULT_START_DATE = startDate // Today
    // this.DEFAULT_END_DATE = new Date(startDate).setDate(date.getDate() + 6) // Today + 6 days
    const rounding = 15 * 60 * 1000; //round up to nearest 15 mins
    let startDate = moment()

    console.log('initial', startDate);
    startDate = moment(Math.ceil((+startDate) / rounding) * rounding);
    console.log('rounded', startDate);

    this.state = {
      startDate: moment(startDate).valueOf(),
      endDate: moment(startDate).add(3, 'days').valueOf(),
      name: "",
      email: "",
      phone: "",
      remarks: "",
      days: 3,
      subTotal: 3*this.props.car.price,
      validate: {
        emailState: '',
        phoneState: ''
      },
    }

    this.onDatePickerChange = this.onDatePickerChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.nameChange = this.nameChange.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.phoneChange = this.phoneChange.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
    this.remarksChange = this.remarksChange.bind(this)
    this.redirectToCheckout = this.redirectToCheckout.bind(this)

  }

  componentDidMount(){
    const db = getFirebase().firestore();
    this.setState({firebaseDB: db})
    this.stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBKEY)
    console.log(this.props)
    console.log("current env:", process.env.GATSBY_ENV_STATUS);
  }

  async redirectToCheckout(docRefId) {
    const { error }  = await  this.stripe.redirectToCheckout({
      items: [
        {
          sku: process.env.GATSBY_ENV_STATUS === "production" ? this.props.car.stripeProdSKU : this.props.car.stripeTestSKU,
          quantity: this.state.days
        },
      ],
      locale: "auto",
      customerEmail: this.state.email,
      clientReferenceId: docRefId,
      submitType: 'book',
      successUrl: `${process.env.GATSBY_API_URL}/bookingSuccess`,
      cancelUrl: `${process.env.GATSBY_API_URL}`,
      // successUrl: `http://localhost:8000/bookingSuccess`,
      // cancelUrl: `http://localhost:8000/`,
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state

    if(!e.target.value) {
      this.setState({isEmailEmpty: true})
      return
    }

    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate, isEmailEmpty: false })
  }

  validatePhone(e) {
    const phoneRex = /^\d{8}$/;
    const { validate } = this.state

    if (phoneRex.test(e.target.value)) {
      validate.phoneState = 'has-success'
    } else {
      validate.phoneState = 'has-danger'
    }
    this.setState({ validate })

  }

  // handleChange = async (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const { name } = target;
  //   await this.setState({
  //     [ name ]: value,
  //   });
  // }

  nameChange(e){
    this.setState({name: e.target.value})
  }

  emailChange(e){
    this.validateEmail(e)
    this.setState({email: e.target.value})
  }

  phoneChange(e){
    this.validatePhone(e)
    this.setState({phone: e.target.value})
  }

  remarksChange(e){
    this.setState({remarks: e.target.value})
  }


  onFormSubmit(event){
    console.log('in form submit');
    event.preventDefault();
    let fbdb = this.state.firebaseDB;
    const car = this.props.car

    let { name, email, phone, startDate, endDate, days, subTotal, remarks } = this.state
    console.log(this.state);

    if (!name || !email || !phone ){
      console.log('required fields not all filled');

    } else {
      console.log('required fields all filled');

      let timestamp = firebase.firestore.FieldValue.serverTimestamp()

      console.log('server timestamp', timestamp);
      console.log('local time', new Date());

      console.log(this.state);
      fbdb.collection('bookings').add({
        name,
        email,
        phone,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        days,
        subTotal,
        remarks,
        carModel: car.model,
        dayPrice: car.price,
        createdAt: timestamp,
        createdEnv: process.env.GATSBY_ENV_STATUS
      })
      .then((docRef)=>{
        console.log("Doc written with ID", docRef.id);
        this.redirectToCheckout(docRef.id)
      })
      .catch((error)=>{
        console.error("Error adding doc: ", error);
      })

    }



  }


  onDatePickerChange(startDate, endDate) {

    console.log(startDate, endDate);
    if(endDate) {
      let days = moment(endDate).diff(moment(startDate), 'days') + 1
      let subTotal = days*this.props.car.price
      this.setState({ startDate, endDate, days, subTotal })
    } else {
      this.setState({ startDate, endDate })
    }

  }

  render(){
    const car = this.props.car
    // console.log(car);
    return (
      <div style={{overflowX:'hidden'}}>
        <Layout>
          <Container className="orderPage-container">
            <Row>
              <Col xs="12" sm="12" md="4"className="d-flex flex-column">
                <label>已選車款</label>
                <p className="h5 pb-2">{car.made} {car.model}</p>
                <div className="text-center p-3">
                  <img className="car-image" src={require(`../images/cars/${car.image}`)} alt=""/>
                </div>
                <h6 className="pt-1 label-text">價錢: </h6>
                <p className="value-text">
                  HKD {car.price}
                  <small className="small-text"> / 每日</small>
                </p>

                <Row>
                  <Col xs="6" sm="6">
                    <h6 className="pt-1 label-text">取車: </h6>
                    <p className="value-text">
                      {moment(this.state.startDate).format('YYYY-MMM-D')}  {moment(this.state.startDate).format('h:mm A')}
                    </p>
                  </Col>

                  <Col xs="6" sm="6">
                    <h6 className="pt-1 label-text">還車: </h6>
                    <p className="value-text">
                      { this.state.endDate ? moment(this.state.endDate).format('YYYY-MMM-D') : ""}  { this.state.endDate ? moment(this.state.endDate).format('h:mm A') : "" }
                    </p>
                  </Col>

                </Row>

                <Row>
                  <Col xs="6" sm="6">
                    <h6 className="pt-1 label-text">出租日數: </h6>
                    <p className="value-text">
                      {this.state.days} 天
                      {/*<small className="text-muted"> 日</small>*/}
                    </p>
                  </Col>

                  <Col xs="6" sm="6">
                    <h6 className="pt-1 label-text">合計: </h6>
                    <p className="value-text">
                      HKD {Number(this.state.subTotal).toLocaleString()}
                      {/*<small className="text-muted"> 日</small>*/}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" sm="12" md="4">
                <label>預約日期及時間</label>
                <div className="calendarWrap d-flex justify-content-center">
                  <ReactLightCalendar
                    dayLabels={DAY_LABELS}
                    monthLabels={MONTH_LABELS}
                    onChange={this.onDatePickerChange}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    disableDates={date => date < new Date().getTime()}
                    range displayTime
                    timezone={"Asia/Hong_Kong"}
                  />
                </div>

              </Col>
              <Col xs="12" sm="12" md="4">
                <Form className="orderForm" onSubmit={this.onFormSubmit}>
                  <FormGroup>
                    <label>名稱*</label>
                    <Input
                      placeholder="Taiman Chan" type="text"
                      onChange={this.nameChange}
                      required
                    />
                  <FormFeedback>名稱不能留空</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <label>電郵地址*</label>
                    <Input
                      placeholder="taiman.chan@gmail.com" type="email"
                      onChange={this.emailChange}
                      required
                      valid={ this.state.validate.emailState === 'has-success' }
                      invalid={ this.state.validate.emailState === 'has-danger' }
                    />
                    <FormFeedback valid>Email 格式正確</FormFeedback>
                    <FormFeedback>{ this.state.isEmailEmpty ? "Email 不能留空" : "Email 格式錯誤" }</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <label>電話* (香港八位數字電話號碼)</label>
                    <Input
                      placeholder="98765432"
                      onChange={this.phoneChange}
                      maxLength={8}
                      type="tel"
                      required
                      valid={ this.state.validate.phoneState === 'has-success' }
                      invalid={ this.state.validate.phoneState === 'has-danger' }
                    />
                    <FormFeedback valid>電話格式正確</FormFeedback>
                    <FormFeedback>電話格式錯誤</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <label>特別要求/備註 (選填)</label>
                    <Input
                      placeholder="" type="textarea" rows="4"
                      onChange={this.remarksChange}
                    />
                  </FormGroup>
                  <Button
                    className="submit-btn"
                    type="submit"
                  >
                    前往付款
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Layout>
      </div>
    )
  }
}

// export default CarDetails
