import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.GATSBY_APIKEY,
    authDomain: process.env.GATSBY_AUTHDOMAIN,
    databaseURL: process.env.GATSBY_DATABASEURL,
    projectId: process.env.GATSBY_PROJECTID,
    storageBucket: process.env.GATSBY_STORAGEBUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGINGSENDERID,
    appId: process.env.GATSBY_APPID,
    measurementId: process.env.GATSBY_MEASUREMENTID
};

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;

    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }
  return null;
}


// let firebaseInstance
//
// export const getFirebase = firebase => {
//   if (firebaseInstance) {
//     return firebaseInstance
//   }
//
//   firebase.initializeApp(firebaseConfig)
//   firebase.analytics();
//   firebaseInstance = firebase;
//
//   return firebase
// }
